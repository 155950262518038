<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_shippingScheduleList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                :rules="[rules.supplierRequired]"
                :error-messages="alertSupplierMessage"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingSelected"
                :items="shippingList"
                item-text="displayText"
                item-value="value"
                :label="$t('label.lbl_shippingTo')"
                :hint="setSrhArrival()"
                class="txt-single"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="getSuppliersSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <!-- 出荷予定日From-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="shippingScheduleDateFrom"
                :label="$t('label.lbl_scheduleDate') + '（From）'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="shippingScheduleDateFromCal"
                  @input="dateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷予定日To-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="shippingScheduleDateTo"
                :label="$t('label.lbl_scheduleDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              >
              </v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="shippingScheduleDateToCal"
                  @input="dateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 納品日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="deliveryDate"
                :label="$t('label.lbl_delDate')"
                @change="changeDeliveryDateTo"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[
                  rules.yyyymmdd_create,
                  rules.isFutureDate(
                    deliveryDate,
                    shippingScheduleDateFrom,
                    $t('label.lbl_scheduleDate') + '（From）'
                  ),
                ]"
              >
              </v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuDeliveryDate"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryDateToCal"
                  @input="dateMenuDeliveryDate = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDeliveryDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDeliveryDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->
          </v-row>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 発行条件（送り状）-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="issueTermsSelected"
                :items="issueTermsList"
                :label="$t('label.lbl_issueTerms') + '（' + $t('label.lbl_invoice') + ')'"
                :hint="setTerms()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 発行条件（荷札）-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="tagTermsSelected"
                :items="issueTermsList"
                :label="$t('label.lbl_issueTerms') + '（' + $t('label.lbl_shippingTag') + '）'"
                :hint="setTagTerms()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 輸送会社名 -->
            <div class="search-textbox">
              <v-text-field
                outlined
                dense
                v-model="truckNm"
                :label="$t('label.lbl_shippingCompanyName')"
                class="txt-single"
                readonly
                append-icon="mdi-close-circle"
                @click:append="append(), (truckNm = '')"
              />
            </div>
            <!-- トラックボタン -->
            <div>
              <v-btn color="primary" class="api-btn" v-on:click="openTruck">
                {{ $t("btn.btn_addShpAdress") }}
              </v-btn>
            </div>
            <span class="item-spacer">&nbsp;</span>
            <!-- 作成日From-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="createDateFrom"
                :label="$t('label.lbl_createDate') + '（From）'"
                @change="changecreateDateFrom"
                :rules="[
                  rules.inputRequiredCreate(createDateFrom, createDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateCreateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="createDateFromCal"
                  @input="dateCreateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="ctprevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="ctnextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 作成日To-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="createDateTo"
                :label="$t('label.lbl_createDate') + '（To）'"
                @change="changecreateDateTo"
                :rules="[
                  rules.inputRequiredCreate(createDateTo, createDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="createalertMessage"
              >
              </v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateCreateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="createDateToCal"
                  @input="dateCreateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="ctprevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="ctnextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="getApiList(true, true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area">
                <v-btn class="post-btn" @click="getInvoiceIssue()">
                  <!--送り状発行ボタン-->
                  {{ $t("label.lbl_invoice") + $t("label.lbl_print") }}
                </v-btn>
                <v-btn
                  v-if="tagExportRole == 'true'"
                  class="post-btn"
                  @click="openNumIssueDialog()"
                >
                  <!--荷札発行ボタン-->
                  {{ $t("label.lbl_shippingTag") + $t("label.lbl_print") }}
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
            <!-- ページング数 -->
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="530px"
          :options.sync="sortOptions"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }" class="product-fixed1">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>

          <!-- 出荷No -->
          <template v-slot:[`item.shipNo`]="{ item }">
            <div class="listNo-style">{{ item.shipNo }}</div>
          </template>

          <!-- 輸送会社 -->
          <template v-slot:[`item.truck`]="{ item }">
            <span v-if="item.truck == '路線'">{{ item.deliveryTraderName }}</span>
            <span v-else>{{ item.truck }}</span>
          </template>

          <!-- 備考欄 -->
          <template v-slot:[`item.remarks`]="{ item }">
            <div v-if="item.remarks != null">
              <!-- 備考欄  改行&ツールチップ -->
              <div v-if="item.remarks.length > 10">
                <!-- 備考欄が10文字以上だった場合ツールチップで表示 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.remarks }}
                    </div>
                  </template>
                  <span> {{ item.remarks }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <!-- 品名が9文字以下だった場合ツールチップ無しで表示 -->
                {{ item.remarks }}
              </div>
            </div>
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              @click="checkList(item, index)"
              style="transform: scale(2)"
            />
          </template>
          <!-- 詳細ボタン -->
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn small @click="openUpdateDelete(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.achieveAdd`]="{ item }">
            <div id="table-achieveAdd" v-if="item.isOutOrder == '0'">
              <!-- 実績登録ボタン-->
            </div>
            <div v-else>
              <v-btn small @click="openAchieveAdd(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
          <!-- 登録方法 -->
          <template v-slot:[`item.howToRegister`]="{ item }">
            <p class="txt-center" style="color: red" v-if="item.entryDiv == '03'">
              {{ item.howToRegister }}
            </p>
            <p class="txt-center" v-else>{{ item.howToRegister }}</p>
          </template>
          <!-- 取込時間 -->
          <template v-slot:[`item.retrieveTime`]="{ item }">
            <p class="txt-center">{{ item.retrieveTime }}</p>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />

      <!-- トラック検索モーダル -->
      <v-dialog v-model="isOpenTruck" :max-width="800">
        <Truck
          :isOpenDetailDgialog.sync="isOpenTruck"
          ref="truck"
          @click="saveTruck"
          :truckInfo="truckInfo"
        />
      </v-dialog>

      <v-dialog v-model="numberIssuesDialog" :max-width="280" origin:top>
        <!-- 荷札発行枚数のダイアログ -->
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name"> {{ $t("label.lbl_numberIssue") }}</span>
          </v-card-title>
          <v-form ref="numIssueVali" lazy-validation>
            <v-container>
              <v-row>
                <div class="add-textbox-num">
                  <v-text-field
                    outlined
                    dense
                    v-model="numIssue"
                    :label="$t('label.lbl_numberIssue')"
                    clear-icon="mdi-close-circle"
                    :rules="[rules.isNumber, rules.zeroCheck]"
                    maxlength="4"
                    @change="changeNum(numIssue)"
                    clearable
                  >
                  </v-text-field>
                </div>
                <span class="require" style="padding-top: 20px">*</span>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="search-row-exeBtn">
              <div class="btn-search-area" style="float: left">
                <v-btn class="other-btn" style="margin-left: 0" @click="numIssueClose">
                  {{ $t("btn.btn_close") }}
                </v-btn>
                <v-btn class="post-btn" @click="numIssueSave">
                  {{ $t("btn.btn_print") }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Truck from "../common/Truck";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
//import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_SHP_003,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    Truck,
    NavBar,
  },
  mixins: [commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    u_10: false,
    involce: "",
    // 輸送会社ダイアログ
    isOpenTruck: false,
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 輸送会社データ項目
    truckInfo: {
      suppliersSelected: "",
      truckInfo: "",
    },
    // ロケーションダイアログ
    dialogLocation: false,
    // 出荷予定日From
    shippingScheduleDateFromCal: "",
    shippingScheduleDateFrom: "",
    // 出荷予定日To
    shippingScheduleDateToCal: "",
    shippingScheduleDateTo: "",
    // 作成日From
    createDateFromCal: "",
    createDateFrom: "",
    // 作成日To
    createDateToCal: "",
    createDateTo: "",
    // 納品日
    deliveryDate: "",
    deliveryDateToCal: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // 出荷先
    shippingSelected: "",
    // 取引先
    suppliersSelected: "",
    // 発行条件(送り状)
    issueTermsSelected: "",
    // 発行条件(荷札)
    tagTermsSelected: "",
    // メニュー
    openMenu: null,
    // 出荷予定Fromカレンダー
    dateMenuFrom: false,
    // 出荷予定Toカレンダー
    dateMenuTo: false,
    //作成日Fromカレンダー
    dateCreateMenuFrom: false,
    //作成日Toカレンダー
    dateCreateMenuTo: false,
    // 納品日カレンダー
    dateMenuDeliveryDate: false,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    // バリデーションチェックメッセージダイアログ
    createalertMessage: "",
    // 荷札発行枚数ダイアログ
    numberIssuesDialog: false,
    // 荷札発行枚数
    numIssue: "",
    // 取引先エラーメッセージ
    alertSupplierMessage: "",
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      {
        text: "選択",
        value: "check",
        width: "50px",
        align: "center",
        class: "product-fixed1",
        sortable: false,
      },
      // 出荷日
      {
        text: i18n.tc("label.lbl_scheduleDate"),
        value: "shippingScheduleDateJ",
        width: "120px",
        align: "left",
        class: "product-fixed2",
        sortable: true,
      },
      // 出荷No
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "shipNo",
        width: "180px",
        align: "left",
        class: "product-fixed3",
        sortable: true,
      },
      // 有償/無償
      {
        text: i18n.tc("label.lbl_isPaid"),
        value: "isPaid",
        width: "130px",
        align: "left",
        sortable: true,
      },
      // 出荷日
      {
        text: i18n.tc("label.lbl_delDate"),
        value: "deliveryDate",
        width: "120px",
        align: "left",
        class: "product-fixed2",
        sortable: true,
      },
      // 処理区分
      {
        text: i18n.tc("label.lbl_processingdivision"),
        value: "processingdivision",
        width: "130px",
        align: "left",
        sortable: true,
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_destination"),
        value: "shippingNm",
        width: "170px",
        align: "left",
        sortable: true,
      },
      // 輸送会社名
      {
        text: i18n.tc("label.lbl_shippingCompanyName"),
        value: "truck",
        align: "left",
        width: "160px",
        sortable: true,
      },
      // 号車
      {
        text: i18n.tc("label.lbl_shipTruckNum"),
        value: "transportVehicleCd",
        width: "140px",
        align: "center",
        sortable: true,
      },
      // 備考
      {
        text: i18n.tc("label.lbl_remarksTextbox"),
        value: "remarks",
        width: "240px",
        align: "left",
        sortable: false,
      },
      // 出庫済
      {
        text: i18n.tc("label.lbl_exited"),
        value: "exted",
        width: "140px",
        align: "center",
        sortable: true,
      }, // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 実績登録
      {
        text: i18n.tc("btn.btn_achieveAdd"),
        sortable: false,
        value: "achieveAdd",
        align: "center",
        width: "140px",
      },
      // 登録方法
      {
        text: i18n.tc("label.lbl_howtoRegister"),
        value: "howToRegister",
        width: "150px",
        align: "center",
        sortable: false,
      },
      // 取込時間
      {
        text: i18n.tc("label.lbl_retrieveTime"),
        value: "retrieveTime",
        width: "150px",
        align: "center",
        sortable: false,
      },
      // 状態(送り状)
      {
        text: i18n.tc("label.lbl_involce"),
        value: "involce",
        width: "160px",
        align: "left",
        sortable: true,
      },
      // 状態(荷札)
      {
        text: i18n.tc("label.lbl_taglabel"),
        value: "taglabel",
        width: "150px",
        align: "left",
        sortable: true,
      },
    ],
    // 修正削除画面
    detailDialogData: {},
    // 総件数
    totalCount: 0,
    // 一覧
    inputList: [],
    // 取引先プルダウン
    supplierList: [],
    // 出荷先プルダウン
    shippingList: [],
    // 発行条件プルダウン
    issueTermsList: [],
    // 輸送会社名（画面表示用）
    truckNm: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    // 輸送会社名（登録用）
    truck: "",
    // 荷札発行権限
    tagExportRole: "true",
    // 入力チェック
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      yyyymmdd_create: (value) =>
        !value ||
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      zeroCheck: (value) =>
        commonUtil.zen2han(value).replaceAll(",", "") > 0 || i18n.tc("check.chk_inputMore"),
      inputRequiredCreate: (value1, value2) => !value2 || !!value1 || i18n.tc("check.chk_input"),
    },
    productHintArrival: "",
    getSuppliersSelected: "",
    // 検索時の出荷元
    shippingSelectedSearchCondition: "",
    // 検索時の出荷元リスト
    shippingListSearchCondition: [],
    searchParam: {
      searchShippingScheduleDateFrom: "",
      searchShippingScheduleDateFromCal: "",
      searchShippingScheduleDateTo: "",
      searchShippingScheduleDateToCal: "",
      searchdeliveryDate: "",
      searchdeliveryDateToCal: "",
      searchIssueTermsSelected: "",
      searchTagTermsSelected: "",
      searchCreateDateFrom: "",
      searchCreateDateFromCal: "",
      searchCreateDateTo: "",
      searchCreateDateToCal: "",
      searchTruckNm: "",
      searchTruckKbn: "",
      searchTruckInfo: {},
      searchTruck: "",
    },
  }),

  created() {
    if (this.$route.params.searchParam) {
      this.searchParam = this.$route.params.searchParam;
    }
    this.suppliersSelected = this.$route.params.searchSuppliersSelected;
    this.shippingSelected = this.$route.params.searchShippingSelected;
    this.shippingScheduleDateFrom = this.searchParam.searchShippingScheduleDateFrom;
    this.shippingScheduleDateFromCal = this.searchParam.searchShippingScheduleDateFromCal;
    this.shippingScheduleDateTo = this.searchParam.searchShippingScheduleDateTo;
    this.shippingScheduleDateToCal = this.searchParam.searchShippingScheduleDateToCal;
    this.issueTermsSelected = this.searchParam.searchIssueTermsSelected;
    this.tagTermsSelected = this.searchParam.searchTagTermsSelected;
    this.createDateFrom = this.searchParam.searchCreateDateFrom;
    if (this.$route.params.searchCreateDateFrom) {
      this.createDateFromCal = this.searchParam.searchCreateDateFromCal;
    }
    this.createDateTo = this.searchParam.searchCreateDateTo;
    if (this.searchParam.searchCreateDateTo) {
      this.createDateToCal = this.searchParam.searchCreateDateToCal;
    }
    if (this.searchParam.searchdeliveryDate != null || this.searchParam.searchdeliveryDate != "") {
      this.deliveryDate = this.searchParam.searchdeliveryDate;
    }
    if (
      this.searchParam.searchdeliveryDateToCal != null ||
      this.searchParam.searchdeliveryDateToCal != ""
    ) {
      this.deliveryDateToCal = this.searchParam.searchdeliveryDateToCal;
    }
    this.truckNm = this.searchParam.searchTruckNm;
    this.truckKbn = this.searchParam.searchTruckKbn;
    this.truckInfo = this.searchParam.searchTruckInfo;
    this.truck = this.searchParam.searchTruck;
    this.checkFlg = this.$route.params.checkFlg;

    if (this.suppliersSelected) {
      this.productHintArrival = this.$route.params.shippingNm;
      this.shippingSelectedSearchCondition = this.shippingSelected;
      let shippingList = this.$route.params.shippingList;
      this.shippingListSearchCondition = this.shippingList;
      this.shippingList = shippingList ? shippingList : [];
      this.getApiList(true, true);
    } else {
      this.suppliersSelected = "";
    }
  },

  methods: {
    append() {
      this.truckKbn = "";
    },
    link() {
      location.href = this.returnURL;
    },

    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getApiList(false, false);
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false, false);
    },

    /**
     * 出荷予定一覧画面：全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          this.listCheckboxBtn.push(this.inputList[i]);
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
        }
        this.isAllSelected = false;
      }
    },

    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];

          if (this.$route.params.searchSuppliersSelected) {
            // this.getSuppliersSelected();
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 発行条件（送り状・荷札）
      const termsList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_INVOICE_DIV);

      Promise.all([termsList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.issueTermsList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 荷札発行枚数ダイアログ、とじる
     */
    numIssueClose() {
      this.$refs.numIssueVali.resetValidation();
      this.numIssue = "";
      this.numberIssuesDialog = false;
    },

    /**
     * 荷札発行枚数ダイアログ、登録
     */
    numIssueSave() {
      if (this.$refs.numIssueVali.validate()) {
        // 荷札発行API実行
        this.getTagIssue();
        this.numberIssuesDialog = false;
        this.$refs.numIssueVali.resetValidation();
        this.numIssue = "";
      } else {
        // 処理なし
      }
    },

    /**
     * 出荷実績登録画面に遷移します。
     */
    openAchieveAdd(item) {
      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);

      // 取引先名と取引先コードを取得
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.inputList[this.editedIndex].clientSid) {
          this.suppliersNm = this.supplierList[i].text;
        }
      }

      // 出荷実績登録画面へ渡す値を設定します。
      this.$router.push({
        name: appConfig.SCREEN_ID.P_SHP_006,
        params: {
          // 出荷伝票SID
          shipSid: this.inputList[this.editedIndex].shipSid,
          // 出荷伝票No
          shipNo: this.inputList[this.editedIndex].shipNo,
          // 出荷日
          shippingScheduleDate: this.inputList[this.editedIndex].shippingScheduleDateJ,
          // 有償/無償名
          freeCostDivName: this.inputList[this.editedIndex].freeCostDivName,
          // 処理区分名
          processingdivision: this.inputList[this.editedIndex].processingdivision,
          // 輸送会社名
          truck: this.inputList[this.editedIndex].truck,
          // 出荷先SID
          toSid: this.inputList[this.editedIndex].toSid,
          // 更新日時
          updateDatetime: this.inputList[this.editedIndex].updateDatetime,
          // 有償無償区分
          isPaid: this.inputList[this.editedIndex].isPaid,
          // 品質区分
          qualityDiv: this.inputList[this.editedIndex].qualityDiv,
          // 責任区分
          blameDiv: this.inputList[this.editedIndex].blameDiv,
          // 予定明細
          dtlList: this.inputList[this.editedIndex].dtlList,
          // 取引先SID
          suppliersSelected: this.inputList[this.editedIndex].clientSid,
          // 出荷先SID
          shippingSelected: this.shippingSelectedSearchCondition,
          // 出荷日From
          shippingScheduleDateFrom: this.shippingScheduleDateFrom,
          // 出荷日Fromカレンダー
          shippingScheduleDateFromCal: this.shippingScheduleDateFromCal,
          // 出荷日To
          shippingScheduleDateTo: this.shippingScheduleDateTo,
          // 出荷日Toカレンダー
          shippingScheduleDateToCal: this.shippingScheduleDateToCal,
          // 発行条件（送り状）コード値
          issueTermsSelected: this.issueTermsSelected,
          // 発行条件（荷札）コード値
          tagTermsSelected: this.tagTermsSelected,
          //作成日From
          createDateFrom: this.createDateFrom,
          //作成日To
          createDateTo: this.createDateTo,
          // 輸送会社名
          truckNm: this.truckNm,
          // 実績登録画面表示用取引先
          suppliersNm: this.suppliersNm,
          // 実績登録画面表示用出荷先
          shippingNm:
            commonUtil.omit(
              this.inputList[this.editedIndex].toBaseCd,
              appConfig.CONSTANT.BASE_CD_DISPLAY_LENGTH
            ) +
            "（" +
            this.inputList[this.editedIndex].shippingNm +
            "）",
          // 画面遷移判断フラグ
          checkFlg: this.checkFlg,
          // 出荷先一覧
          shippingList: this.shippingListSearchCondition,
          // 検索条件
          searchParam: this.searchParam,
        },
      });
      // }
    },

    /**
     * 荷札発行枚数ダイアログ、登録
     */
    openNumIssueDialog() {
      if (this.listCheckboxBtn.length > 0) {
        // 発行枚数・バリデーションエラーを初期化
        if (this.numIssue) {
          this.$refs.numIssueVali.reset();
          this.numIssue = "";
        }
        this.numberIssuesDialog = true;
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },

    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.truckInfo = value;

      // 画面表示用
      if (!this.truckInfo.carrierCompName && this.truckInfo.carrierCompCd == "04") {
        this.truckNm = i18n.tc("label.lbl_route");
      } else {
        this.truckNm = this.truckInfo.carrierCompName;
      }
      // API検索用
      this.truckKbn = this.truckInfo.carrierCompCd;
      // this.truckKbn = this.truckInfo.carrierCompCd;
      this.truck = this.truckInfo.deliveryTraderSid;
      this.truckName = this.truckInfo.deliveryTraderName;
      this.isOpenTruck = false;
    },

    /**
     * 入力カンマ処理 荷札発行枚数
     */
    changeNum(numIssue) {
      var num = commonUtil.zen2han(numIssue).replaceAll(",", "");
      if (!isNaN(Number(num))) {
        this.numIssue = commonUtil.formatToCurrency(Number(num));
      }
    },

    /**
     * 出荷予定一覧画面： 検索ボタン押下処理
     */
    getApiList(checkBoxFlag, isSearchConditionUpdate) {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      let check = 0;
      // メッセージ初期化
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      this.createalertMessage = "";
      // 取引先存在チェック
      if (this.suppliersSelected == "" || this.suppliersSelected == null) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        check++;
      }
      if (
        // FromToの日付、入力チェック
        new Date(this.shippingScheduleDateFrom).getTime() >
        new Date(this.shippingScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }
      if (this.createDateFrom && this.createDateTo) {
        if (
          // FromToの日付、入力チェック
          new Date(this.createDateFrom).getTime() > new Date(this.createDateTo).getTime()
        ) {
          this.createalertMessage = i18n.tc("check.chk_inputFromVali");
          check++;
        }
      }
      if (check == 0) {
        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するためこのようなコードとなっている。
         */
        if (this.$route.params.searchSuppliersSelected && this.checkFlg) {
          // 登録画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }
        // 画面遷移フラグをfalseに戻す
        this.checkFlg = false;

        if (inputCheckResult) {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";
          // 初期検索かページング検索か判断
          if (checkBoxFlag) {
            this.isAllSelected = false;
          }
          this.shippingSelectedSearchCondition = this.shippingSelected;
          this.shippingListSearchCondition =
            this.shippingSelected == "" || this.shippingSelected == null
              ? []
              : new Array(...this.shippingList);

          // 検索条件を保存
          console.log(this.searchParam);
          this.searchParam.searchShippingScheduleDateFrom = this.shippingScheduleDateFrom;
          this.searchParam.searchShippingScheduleDateFromCal = this.shippingScheduleDateFromCal;
          this.searchParam.searchShippingScheduleDateTo = this.shippingScheduleDateTo;
          this.searchParam.searchShippingScheduleDateToCal = this.shippingScheduleDateToCal;
          this.searchParam.searchIssueTermsSelected = this.issueTermsSelected;
          this.searchParam.searchTagTermsSelected = this.tagTermsSelected;
          this.searchParam.searchdeliveryDate = this.deliveryDate;
          this.searchParam.searchdeliveryDateToCal = this.deliveryDateToCal;
          this.searchParam.searchCreateDateFrom = this.createDateFrom;
          this.searchParam.searchCreateDateFromCal = this.createDateFromCal;
          this.searchParam.searchCreateDateTo = this.createDateTo;
          this.searchParam.searchCreateDateToCal = this.createDateToCal;
          this.searchParam.searchTruckNm = this.truckNm;
          this.searchParam.searchTruckKbn = this.truckKbn;
          this.searchParam.searchTruckInfo = this.truckInfo;
          this.searchParam.searchTruck = this.truck;

          // ローディング画面表示ON
          this.loadingCounter = 1;

          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();
          // 営業所SID（ログインユーザ情報）
          config.params.officeSid = sessionStorage.getItem("sales_office_sid");
          // 取引先SID
          config.params.clientSid = this.suppliersSelected;
          // 出荷予定日From
          config.params.outScheduleDateFrom = dateTimeHelper.convertUTC(
            this.shippingScheduleDateFrom
          );
          // 出荷予定日To
          var arr = this.shippingScheduleDateTo + " 23:59:59.999";
          config.params.outScheduleDateTo = dateTimeHelper.convertUTC(arr);
          //作成日From
          if (this.createDateFrom) {
            config.params.createDateFrom = dateTimeHelper.convertUTC(this.createDateFrom);
          }
          //作成日To
          if (this.createDateTo) {
            config.params.createDateTo = dateTimeHelper.convertUTC(
              this.createDateTo + " 23:59:59.999"
            );
          }
          //納品日
          if (this.deliveryDate) {
            config.params.deliveryDate = dateTimeHelper.convertUTC(
              this.deliveryDate + " 23:59:59.999"
            );
          }
          // 出荷先
          if (this.shippingSelected) {
            // ToSID（出荷先SID）
            config.params.toSid = this.shippingSelected;
          }
          // 路線業者SID（輸送会社名)
          if (this.truckKbn != "") {
            config.params.carrierCompCd = this.truckKbn;
            if (this.truck != "") {
              config.params.deliveryTraderSid = this.truck;
            }
          }
          if (this.issueTermsSelected) {
            // 発行条件（送り状）
            config.params.invoicePrintCd = this.issueTermsSelected;
          }
          if (this.tagTermsSelected) {
            // 発行条件（荷札）
            config.params.taglabelPrintCd = this.tagTermsSelected;
          }

          // ページャー処理
          if ((this.page >= 2 && this.inputList.length == 0) || isSearchConditionUpdate) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageLimit = this.itemsPerPage;

          if (this.sortItem != "") {
            config.params.reqComSortItem = this.sortItem;
          }

          // console.debug("getApiList(checkBoxFlag) Config", config);
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_OUTSCHEDULES, config)
              .then((response) => {
                // console.debug("getApiList(checkBoxFlag) Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                //headerItems内削除
                this.headerItems.splice(0, this.headerItems.length);

                //headerItems設定
                this.headerItems.push(
                  {
                    text: "選択",
                    value: "check",
                    width: "50px",
                    align: "center",
                    class: "product-fixed1",
                    sortable: false,
                  },
                  // 出荷日
                  {
                    text: i18n.tc("label.lbl_scheduleDate"),
                    value: "shippingScheduleDateJ",
                    width: "120px",
                    align: "left",
                    class: "product-fixed2",
                    sortable: true,
                  }
                );

                // 正常時
                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  if (jsonData.resCom.resComPagination.totalRecord == 0) {
                    this.headerItems.push(
                      // 出荷No
                      {
                        text: i18n.tc("label.lbl_shippingNo"),
                        value: "shipNo",
                        width: "180px",
                        align: "left",
                        class: "product-fixed3",
                        sortable: true,
                      }
                    );
                  } else {
                    this.headerItems.push(
                      // 出荷No
                      {
                        text: i18n.tc("label.lbl_shippingNo"),
                        value: "shipNo",
                        width: "180px",
                        align: "left",
                        class: "product-fixed3-search",
                        sortable: true,
                      }
                    );
                  }
                } else {
                  this.headerItems.push(
                    // 出荷No
                    {
                      text: i18n.tc("label.lbl_shippingNo"),
                      value: "shipNo",
                      width: "180px",
                      align: "left",
                      class: "product-fixed3",
                      sortable: true,
                    }
                  );
                }

                //headerItems設定
                this.headerItems.push(
                  // 有償/無償
                  {
                    text: i18n.tc("label.lbl_isPaid"),
                    value: "isPaid",
                    width: "130px",
                    align: "left",
                    sortable: true,
                  },
                  // 納品日
                  {
                    text: i18n.tc("label.lbl_delDate"),
                    value: "deliveryDate",
                    width: "120px",
                    align: "left",
                    sortable: true,
                  },
                  // 処理区分
                  {
                    text: i18n.tc("label.lbl_processingdivision"),
                    value: "processingdivision",
                    width: "130px",
                    align: "left",
                    sortable: true,
                  },
                  // 出荷先
                  {
                    text: i18n.tc("label.lbl_destination"),
                    value: "shippingNm",
                    width: "170px",
                    align: "left",
                    sortable: true,
                  },
                  // 輸送会社名
                  {
                    text: i18n.tc("label.lbl_shippingCompanyName"),
                    value: "truck",
                    align: "left",
                    width: "160px",
                    sortable: true,
                  },
                  // 号車
                  {
                    text: i18n.tc("label.lbl_shipTruckNum"),
                    value: "transportVehicleCd",
                    width: "140px",
                    align: "center",
                    sortable: true,
                  },
                  // 備考
                  {
                    text: i18n.tc("label.lbl_remarksTextbox"),
                    value: "remarks",
                    width: "240px",
                    align: "left",
                    sortable: false,
                  },
                  // 出庫済
                  {
                    text: i18n.tc("label.lbl_exited"),
                    value: "exted",
                    width: "140px",
                    align: "center",
                    sortable: true,
                  },
                  // 詳細
                  {
                    text: i18n.tc("label.lbl_Detail"),
                    value: "detail",
                    width: "100px",
                    align: "center",
                    sortable: false,
                  },
                  // 実績登録
                  {
                    text: i18n.tc("btn.btn_achieveAdd"),
                    sortable: false,
                    value: "achieveAdd",
                    align: "center",
                    width: "140px",
                  },
                  // 登録方法
                  {
                    text: i18n.tc("label.lbl_howtoRegister"),
                    value: "howToRegister",
                    width: "150px",
                    align: "center",
                    sortable: false,
                  },
                  // 取込時間
                  {
                    text: i18n.tc("label.lbl_retrieveTime"),
                    value: "retrieveTime",
                    width: "150px",
                    align: "center",
                    sortable: false,
                  },
                  // 状態(送り状)
                  {
                    text: i18n.tc("label.lbl_involce"),
                    value: "involce",
                    width: "160px",
                    align: "left",
                    sortable: true,
                  },
                  // 状態(荷札)
                  {
                    text: i18n.tc("label.lbl_taglabel"),
                    value: "taglabel",
                    width: "150px",
                    align: "left",
                    sortable: true,
                  }
                );

                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  const list = [];
                  let involceIn = "";
                  let taglabelIn = "";
                  let exited = "";
                  // 出荷予定リストを取得
                  jsonData.resIdv.outSchedules.forEach((row) => {
                    if (row.invoicePrintDate) {
                      // 状態（送り状）あり
                      involceIn = i18n.tc("label.lbl_print");
                    } else {
                      // 状態（送り状）なし
                      involceIn = i18n.tc("label.lbl_notPrint");
                    }

                    if (row.taglabelPrintDate) {
                      // 状態（荷札）あり
                      taglabelIn = i18n.tc("label.lbl_print");
                    } else {
                      // 状態（荷札）なし
                      taglabelIn = i18n.tc("label.lbl_notPrint");
                    }
                    // if (row.wareOutOrderCnt < 1) {
                    //   // 未出庫
                    //   exited = i18n.tc("label.lbl_unshipped");
                    // } else if ( row.wareOutOrderCnt >= 1 && row.wareOutOrderCnt == row.wareOutOrderComplete) {
                    //   // 出庫済
                    //   exited = i18n.tc("label.lbl_shipped");
                    // } else if (row.wareOutOrderCnt >= 1 && row.wareOutOrderCnt != row.wareOutOrderComplete) {
                    //   // 出庫中（引当済）
                    //   exited = i18n.tc("label.lbl_shippedNow");
                    // }
                    if (
                      row.wareOutOrderComplete == 0 ||
                      row.wareOutOrderCnt > row.wareOutOrderComplete
                    ) {
                      // 未出庫
                      exited = i18n.tc("label.lbl_unshipped");
                    } else {
                      // 出庫済
                      exited = i18n.tc("label.lbl_shipped");
                    }

                    list.push({
                      // 出荷日（API登録用=UTC）
                      shippingScheduleDate: row.outScheduleDate,
                      // 出荷日（画面表示用=JST:秒数なし）
                      shippingScheduleDateJ: dateTimeHelper
                        .convertUTC2JST(row.outScheduleDate)
                        .substr(0, 10),
                      // 出荷SID
                      shipSid: row.outListSid,
                      // 出荷No
                      shipNo: row.outListNo,
                      //出荷先コード
                      toBaseCd: row.toBaseCd,
                      // 有償/無償
                      isPaid: row.freeCostDivName,
                      // 有償無償名
                      freeCostDivName: row.freeCostDivName,
                      // 状態（送り状）
                      involce: involceIn,
                      // 状態（荷札）
                      taglabel: taglabelIn,
                      // 状態（荷札）
                      deliveryDate: row.deliveryDate,
                      // 処理区分名
                      processingdivision: row.processDivName,
                      // 出荷先
                      shippingNm: row.toName,
                      // 出荷先SID
                      toSid: row.toSid,
                      // 輸送会社名
                      truck: row.carrierCompCdName,
                      // 輸送会社区分
                      carrierCompCd: row.carrierCompCd,
                      // 輸送会社種別名
                      carrierCompName: row.carrierCompName,
                      // 号車
                      transportVehicleCd: row.transportVehicleCd,
                      // 備考
                      remarks: row.remarks,
                      // 出庫済
                      exted: exited,
                      // 路線業者SID
                      traderSid: row.deliveryTraderSid,
                      // 路線業者名
                      deliveryTraderName: row.deliveryTraderName,
                      // 品質区分
                      qualityDiv: row.qualityDiv,
                      // 責任区分
                      blameDiv: row.blameDiv,
                      // チェックボックス
                      check: false,
                      // 出荷予定明細
                      dtlList: row.outScheduleDtl,
                      // 完了フラグ
                      completeFlg: row.completeFlg,
                      // 更新日時
                      updateDatetime: row.updateDatetime,
                      // 出荷指示一覧フラグ
                      isOutOrder: row.isOutOrder,
                      // 登録方法
                      howToRegister: row.entryDivName,
                      // 登録コード
                      entryDiv: row.entryDiv,
                      // データ取込時間
                      retrieveTime: dateTimeHelper.convertUTC2JST(row.createDatetime).substr(0, 19),
                      // 取引先SID
                      clientSid: row.clientSid,
                    });
                  });
                  this.inputList = list;

                  if (!checkBoxFlag) {
                    // 検索ボタン以外で検索処理が走った場合、元々チェックしていたレコードにチェックをいれる
                    for (var k = 0; k < this.inputList.length; k++) {
                      for (var j = 0; j < this.listCheckboxBtn.length; j++) {
                        if (this.inputList[k].shipSid == this.listCheckboxBtn[j].shipSid) {
                          this.inputList[k].check = true;
                        }
                      }
                    }
                  } else {
                    // 検索ボタン押下時は、チェックボックスを詰めていたListを初期化する
                    this.listCheckboxBtn.length = 0;
                  }

                  // ページング検索の場合の全選択チェックボックス処理
                  if (this.isAllSelected) {
                    this.isAllSelected = true;
                    for (var i = 0; i < this.inputList.length; i++) {
                      this.inputList[i].check = true;
                    }
                  }
                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              })
              .then(() => {
                // 明細単位で出庫指示の有無を確認していく（一部出庫指示がない（未引当）けど、一部出庫指示があるパターン）
                for (let i = 0; i < this.inputList.length; i++) {
                  var cnt = 0;
                  for (let j = 0; j < this.inputList[i].dtlList.length; j++) {
                    if (this.inputList[i].dtlList[j].isAllOrder == 0) {
                      // 出庫指示がないレコードをカウントする
                      cnt = cnt + 1;
                    }
                  }

                  // if (this.inputList[i].dtlList.length != cnt) {
                  //   // 明細の数と出庫指示がない（未引当）の数が一致しなかったら、ステータスを「出庫中（一部未引当・出庫実績あり）」とする
                  //   this.inputList[i].exted = i18n.tc("label.lbl_shippedNow");
                  // }
                }
              });
          });
        }
      }
    },

    /**
     * 出荷予定一覧画面： 送り状発行API
     */
    getInvoiceIssue() {
      if (this.listCheckboxBtn.length > 0) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();

        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_003;
        // タイムゾーン
        body.reqIdv.timeZone = dateTimeHelper.getTimeZone();

        // チェック済出荷Noリスト
        const noList = [];
        var onCheckNo = "";
        for (var i = 0; i < this.listCheckboxBtn.length; i++) {
          if (this.listCheckboxBtn[i].check) {
            onCheckNo = this.listCheckboxBtn[i].shipSid;
            noList.push(onCheckNo);
          }
        }

        body.reqIdv.outListSids = noList;

        // console.debug("getInvoiceIssue() Body", body);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_OUTINVOICESHIP, body, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // console.debug("getInvoiceIssue() Response", response);

              // 成功
              if (jsonData.resCom.resComCode == "000") {
                // 返却されたurlをデータに格納、ダウンロードボタンの活性化
                this.returnURL = jsonData.resIdv.filePath;
                this.link();
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 出荷予定一覧画面： 荷札発行API
     */
    getTagIssue() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_003;
      // タイムゾーン
      body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
      // 荷札情報List
      var bodyList = [];

      for (var i = 0; i < this.listCheckboxBtn.length; i++) {
        var inputMap = {};
        // 出荷伝票SID
        inputMap.outListSid = this.listCheckboxBtn[i].shipSid;

        // 荷札明細
        this.taglabelDtls = [];
        for (var j = 0; j < this.listCheckboxBtn[i].dtlList.length; j++) {
          var tagDtlMap = {};
          // 出荷予定明細SID
          tagDtlMap.outListSubSid = this.listCheckboxBtn[i].dtlList[j].outListSubSid;
          // 荷札発行枚数
          tagDtlMap.taglabelCnt = this.numIssue.replaceAll(",", "");
          this.taglabelDtls.push(tagDtlMap);
        }
        inputMap.taglabelDtls = this.taglabelDtls;
        bodyList.push(inputMap);
      }

      body.reqIdv.taglabel = bodyList;

      // console.debug("getTagIssue() Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_TAGLABEL, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("getTagIssue() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              // 返却されたurlをデータに格納、ダウンロードボタンの活性化
              this.returnURL = jsonData.resIdv.filePath;
              this.link();
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            console.error("getTagIssue() Resolve", resolve);

            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.suppliersSelected) {
        // console.debug(this.suppliersSelected);
        // 遷移フラグ
        this.transitionFlg = true;
      } else {
        this.shippingSelected = "";
        this.suppliersSelected = "";
        this.shippingScheduleDate = "";
        this.truckNm = "";
        this.inputList = [];
        this.shippingScheduleDateTo = dateTimeHelper.convertJST();
        this.shippingScheduleDateFrom = dateTimeHelper.convertJST();
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }
      this.getCustomInfo();
      this.getMstCode();

      // 荷札発行権限を取得
      this.tagExportRole = sessionStorage.getItem("P-SHP-003_TAG");
    },

    /**
     * 出荷予定一覧画面：出荷予定修正・削除画面を開きます。
     */
    openUpdateDelete(item) {
      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);
      // 取引先名と取引先コードを取得
      let supliersCompSid = "";
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.inputList[this.editedIndex].clientSid) {
          this.suppliersNm = this.supplierList[i].text;
          supliersCompSid = this.supplierList[i].clientSid;
          break;
        }
      }
      // 修正画面へ渡す値を設定します。
      this.$router.push({
        name: appConfig.SCREEN_ID.P_SHP_004,
        params: {
          // 出荷伝票SID
          shipSid: this.inputList[this.editedIndex].shipSid,
          // 出荷No
          txt_slipNo: this.inputList[this.editedIndex].shipNo,
          // 出荷予定日
          shippingScheduleDate: this.inputList[this.editedIndex].shippingScheduleDateJ,
          // 品質区分名
          shippingStatusSelected: this.inputList[this.editedIndex].shipSid,
          // 責任区分
          isRes: this.inputList[this.editedIndex].blameDiv,
          // 輸送会社
          truckNmHeader: this.inputList[this.editedIndex].truck,
          // 有償無償
          isPaid: this.inputList[this.editedIndex].isPaid,
          // 無償理由
          freeReasonSelected: this.inputList[this.editedIndex].shipSid,
          // 備考欄
          remarks: this.inputList[this.editedIndex].remarks,
          // 処理区分名
          processDivSelected: this.inputList[this.editedIndex].processingdivision,
          // 出荷先SID
          shippingSelectedHeader: this.inputList[this.editedIndex].toSid,
          // 出荷予定日秒数あり
          shippingScheduleDateJ: this.inputList[this.editedIndex].shippingScheduleDate,
          // 輸送会社区分
          carrierCompCd: this.inputList[this.editedIndex].carrierCompCd,
          // 路線業者
          traderSid: this.inputList[this.editedIndex].traderSid,
          // 路線業者名
          deliveryTraderName: this.inputList[this.editedIndex].deliveryTraderName,
          // 予定明細
          dtlList: this.inputList[this.editedIndex].dtlList,
          // 取引先SID
          suppliersSelected: this.inputList[this.editedIndex].clientSid,
          // 出荷先SID
          shippingSelected: this.shippingSelectedSearchCondition,
          // 出荷日From
          shippingScheduleDateFrom: this.shippingScheduleDateFrom,
          // 出荷日Fromカレンダー
          shippingScheduleDateFromCal: this.shippingScheduleDateFromCal,
          // 出荷日To
          shippingScheduleDateTo: this.shippingScheduleDateTo,
          // 出荷日Toカレンダー
          shippingScheduleDateToCal: this.shippingScheduleDateToCal,
          // 発行条件（送り状）コード値
          issueTermsSelected: this.issueTermsSelected,
          // 発行条件（荷札）コード値
          tagTermsSelected: this.tagTermsSelected,
          //作成日From
          createDateFrom: this.createDateFrom,
          //作成日To
          createDateTo: this.createDateTo,
          // 輸送会社名
          truckNm: this.truckNm,
          // 画面遷移判断フラグ
          checkFlg: this.checkFlg,
          // 取引先名
          suppliersNm: this.suppliersNm,
          // 出荷先名(BaseCd付き)
          shippingNm:
            this.inputList[this.editedIndex].toBaseCd +
            "（" +
            this.inputList[this.editedIndex].shippingNm +
            "）",
          // 出荷先名(BaseCd付き)（省略）
          shippingOmitNm:
            commonUtil.omit(
              this.inputList[this.editedIndex].toBaseCd,
              appConfig.CONSTANT.BASE_CD_DISPLAY_LENGTH
            ) +
            "（" +
            this.inputList[this.editedIndex].shippingNm +
            "）",
          // 出荷先一覧
          shippingList: this.shippingListSearchCondition,
          // 検索条件
          searchParam: this.searchParam,
          // 出荷先名
          shipTo: this.inputList[this.editedIndex].shippingNm,
          // BaseCd
          baseCd: this.inputList[this.editedIndex].toBaseCd,
          // 取引先会社SID
          supliersCompSid: supliersCompSid,
          // 取引先リスト
          supplierList: this.supplierList,
        },
      });
      // }
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合

        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);
      } else {
        // チェックを外した場合

        // 外したレコードの出荷SIDを取得
        let inputListOutlistNo = this.inputList[index].shipSid;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.chengeListCheckbox.length; i++) {
          if (this.chengeListCheckbox[i].shipSid != inputListOutlistNo) {
            this.listCheckboxBtn.push(this.chengeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }
      }
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 出荷予定一覧画面：配送先選択ボタン処理
     */
    openTruck() {
      if (this.suppliersSelected) {
        this.truckInfo.suppliersSelected = this.suppliersSelected;
        //路線業者SID
        this.truckInfo.deliveryTraderSid = this.truck;
        //輸送業者コード
        this.truckInfo.carrierCompCd = this.truckKbn;
        this.isOpenTruck = true;
        if (this.$refs.truck != undefined) {
          //表示時処理
          this.$refs.truck.init();
        }
      } else {
        this.suppliersSelected = null;
      }
    },

    /**
     * 出荷日付Fromを－１日します。
     */
    prevDate() {
      if (this.shippingScheduleDateFromCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateFromCal != toDate) {
        this.shippingScheduleDateFromCal = toDate;
      } else {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() - 1)
        );
      }
    },

    /**
     * 出荷日付Fromを＋１日します。
     */
    nextDate() {
      if (this.shippingScheduleDateFromCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateFromCal != toDate) {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 2)
        );
      } else {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 1)
        );
      }
    },

    /**
     * 出荷日付Fromを－１日します。
     */
    prevToDate() {
      if (this.shippingScheduleDateToCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateToCal != toDate) {
        this.shippingScheduleDateToCal = toDate;
      } else {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 出荷日付Fromを＋１日します。
     */
    nextToDate() {
      if (this.shippingScheduleDateToCal == null) {
        return;
      }
      let date = new Date(this.shippingScheduleDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingScheduleDateToCal != toDate) {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    /**
     * 作成日Fromに-1日する。
     */
    ctprevDate() {
      this.createDateFromCal = commonFunction.addDate(this.createDateFromCal, "");
    },
    /**
     * 作成日Fromに+1日する。
     */
    ctnextDate() {
      this.createDateFromCal = commonFunction.addDate(this.createDateFromCal, "add");
    },
    /**
     * 作成日Toに-1日する。
     */
    ctprevToDate() {
      this.createDateToCal = commonFunction.addDate(this.createDateToCal, "");
    },
    /**
     * 作成日Toに+1日する。
     */
    ctnextToDate() {
      this.createDateToCal = commonFunction.addDate(this.createDateToCal, "add");
    },
    /**
     * 納品日Toに-1日する。
     */
    prevToDeliveryDate() {
      this.deliveryDateToCal = commonFunction.addDate(this.deliveryDateToCal, "");
    },
    /**
     * 納品日Toに+1日する。
     */
    nextToDeliveryDate() {
      this.deliveryDateToCal = commonFunction.addDate(this.deliveryDateToCal, "add");
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateFromCal = null;
      }
    },

    changecreateDateFrom(val) {
      this.createDateFromCal = commonFunction.directInput(val);
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateToCal = null;
      }
    },

    changecreateDateTo(val) {
      this.createDateToCal = commonFunction.directInput(val);
    },

    /**
     * 納品日
     */
    changeDeliveryDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.deliveryDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.deliveryDateToCal = null;
      }
    },

    /**
     * 出荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 出荷予定登録画面：出荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      return commonFunction.getListSetName(this.shippingList, this.shippingSelected);
    },

    /**
     * 発行条件リストボックス変換
     */
    setTerms() {
      for (var i = 0; i < this.issueTermsList.length; i++) {
        if (this.issueTermsList[i].value == this.issueTermsSelected) {
          return this.issueTermsList[i].name;
        }
      }
    },
    // 発行条件（荷札）
    setTagTerms() {
      for (var i = 0; i < this.issueTermsList.length; i++) {
        if (this.issueTermsList[i].value == this.tagTermsSelected) {
          return this.issueTermsList[i].name;
        }
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.shippingList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintArrival = val.displayText;
        this.getSuppliersSelected = val.displayText;
      }
      this.shippingList = this.shippingList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintArrival = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.transitionFlg) {
        this.suppliersSelected = "";
        this.issueTermsSelected = "";
        this.tagTermsSelected = "";
        this.deliveryDate = "";
        this.createDateFrom = "";
        this.createDateTo = "";
        this.deliveryDateToCal = "";
        this.createDateFromCal = "";
        this.createDateToCal = "";
        // 遷移フラグ
        this.transitionFlg = false;
      }
      // 変更検知用の設定
      this.shippingScheduleDateFromCal = "";
      this.shippingScheduleDateToCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    suppliersSelected(newValue, oldValue) {
      // 出荷先リスト/輸送会社名クリア
      if (oldValue != null && oldValue != "") {
        this.shippingList = [];
        this.shippingSelected = "";
        this.truckNm = "";
        this.truckKbn = "";
        this.truck = "";
      }

      this.alertSupplierMessage = "";
    },

    shippingScheduleDateFromCal: {
      handler(val) {
        this.shippingScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    shippingScheduleDateToCal: {
      handler(val) {
        this.shippingScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },
    createDateFromCal(val) {
      this.createDateFrom = this.formatDate(val);
    },
    createDateToCal(val) {
      this.createDateTo = this.formatDate(val);
    },

    deliveryDateToCal(val) {
      this.deliveryDate = this.formatDate(val);
    },

    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false, false);
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_SHP_003_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getApiList(false, false);
        }
      },
      deep: true,
    },
    // 出荷先取得
    getSuppliersSelected(val) {
      if (val ?? false) {
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          this.shippingList = [];
          this.shippingSelected = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintArrival) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          let officeCd = this.supplierList.find(
            (element) => element.value === this.suppliersSelected
          ).clientSid;
          // To（出荷先）取得
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(),
            undefined,
            officeCd,
            val
          );
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.shippingList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.shippingList.length = 0;
          this.shippingList = [];
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.link {
  text-decoration: none;
}
.txt-center {
  margin: auto;
}

.add-textbox-num {
  width: 16rem;
  padding: 20px 0 0 10px;
}
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed1 {
  position: sticky !important;
  left: 0 !important;
  z-index: 6 !important;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed2 {
  position: sticky !important;
  left: 50px !important;
  z-index: 6 !important;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed3 {
  position: sticky !important;
  left: 170px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed3-search {
  position: sticky !important;
  left: 170px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(1) {
  position: sticky !important;
  left: 0 !important;
  z-index: 5 !important;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
  position: sticky !important;
  left: 50px !important;
  z-index: 5 !important;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(3) {
  position: sticky !important;
  left: 170px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td {
  background-color: #ffffff;
}

//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr:nth-child(odd) td {
  background-color: #dde5f0;
}
</style>
